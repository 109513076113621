import React from 'react';
import { Language } from '@mui/icons-material';
import { Badge, IconButton, Menu, MenuItem } from '@mui/material';
import i18n from '../../../i18n';
import { useDispatch } from 'react-redux';

const LanguageSelector = () => {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLanguageChange = (lang: string) => {
		dispatch.auth.setLanguage(lang);
		i18n.changeLanguage(lang);
		handleClose();
	};

	const languages: { [key: string]: { label: string; icon: string } } = {
		fr: {
			label: 'Français',
			icon: '\uD83C\uDDEB\uD83C\uDDF7', // Code Unicode pour le drapeau français
		},
		en: {
			label: 'English',
			icon: '\uD83C\uDDEC\uD83C\uDDE7', // Code Unicode pour le drapeau anglais
		},
		es: {
			label: 'Español',
			icon: '\uD83C\uDDEA\uD83C\uDDF8', // Code Unicode pour le drapeau espagnol
		},
		de: {
			label: 'Deutsch',
			icon: '\uD83C\uDDE9\uD83C\uDDEA', // Code Unicode pour le drapeau allemand
		},
		it: {
			label: 'Italiano',
			icon: '\uD83C\uDDEE\uD83C\uDDF9', // Code Unicode pour le drapeau italien
		},
	};

	return (
		<>
			<IconButton
				aria-controls='language-menu'
				aria-haspopup='true'
				onClick={handleClick}
				color='inherit'>
				<Badge
					overlap='circular'
					badgeContent={
						<span style={{ fontSize: '24px' }}>
							{languages[i18n.language.substring(0, 2)]?.icon}
						</span>
					}>
					<Language style={{ width: 30, height: 30 }} />
				</Badge>
			</IconButton>
			<Menu
				id='language-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				{Object.keys(languages).map((lang) => {
					return (
						<MenuItem
							key={languages[lang].label}
							onClick={() => handleLanguageChange(lang)}
							selected={i18n.languages.includes(lang)}>
							<span style={{ marginRight: '10px' }}>{languages[lang].icon}</span>
							{` ${languages[lang].label}`}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export default LanguageSelector;
