import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Box, useMediaQuery } from '@mui/material';
import theme from '../../../styles/theme';

interface IWrapperProps {
	children: ReactNode;
	className?: string;
}

const Wrapper: FC<IWrapperProps> = ({ children, className, ...props }) => {
	const { open } = useSelector((state: RootState) => state.auth);
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box
			sx={{
				backgroundColor: '#FBFBFB',
				minHeight: "calc(100vh - 170px)",
				marginLeft: isMobile ? '0px' : open ? '240px'  : '60px',
				marginTop: '105px',
				padding: { xs: '30px 20px', sm: '30px 50px' },
			}}
			{...props}>
			{children}
		</Box>
	);
};

export default Wrapper;
