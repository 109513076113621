import { useTranslation } from "react-i18next";

const useFields = () => {
	const { t } = useTranslation();

	const fields = [
		{
			type: 'text',
			name: 'name',
			label: t('orga.Nom'),
			placeholder: 'Ex: Organisation',
			style: { marginBottom: '35px' },
		},
		{
			type: 'text',
			name: 'address',
			label: t('orga.Rue'),
			placeholder: 'Ex: 18 Place Roguet',
		},
		{
			type: 'text',
			name: 'postalCode', 
			label: t('orga.Code Postal'),
			placeholder: 'Ex: 31100',
		},
		{
			type: 'text',
			name: 'city',
			label: t('orga.Ville'),
			placeholder: 'Ex: Toulouse',
			style: { marginBottom: 0 },
		},
	];

	return { fields };
};

export default useFields;
