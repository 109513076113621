import * as React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface IFluxSelect {
	selectedFlux: string;
	setSelectedFlux: (name: string) => void;
	displayAll?: boolean;
}
const FluxSelect: React.FC<IFluxSelect> = ({
	selectedFlux,
	setSelectedFlux,
	displayAll = true,
}): JSX.Element => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { flux } = useSelector((state: RootState) => state.flux);

	React.useEffect(() => {
		if (flux.length === 0) dispatch.flux.getAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flux]);

	const uniqFlux = [...new Set(flux.map((f) => f.name))];

	const handleFilterFlux = (event: SelectChangeEvent) => {
		setSelectedFlux(event.target.value);
	};

	return (
		<FormControl sx={{ minWidth: 160 }} size='small'>
			<Select
				labelId='demo-select-small-label'
				id='demo-select-small'
				value={selectedFlux}
				placeholder={t('common.Sélectionner un flux')}
				onChange={handleFilterFlux}>
				{displayAll && <MenuItem value={'All'}>{t('common.Tous les flux')}</MenuItem>}
				{uniqFlux?.map((flx, index) => {
					return (
						<MenuItem key={index} value={flx}>
							{flx}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default FluxSelect;
