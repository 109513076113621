import { Order } from '../types/datatable-types';
import { IOrganisation } from '../types/organisations-types';
import moment from 'moment';

export function capitalizeFirstLetter(text: string): string {
	if (text.length === 0) {
		return text;
	} else {
		return text.charAt(0)?.toUpperCase() + text.slice(1);
	}
}

export const downloadCSVFile = (csvContent: string, filename: string) => {
	const blob = new Blob([csvContent], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);

	// Crée un élément <a> pour télécharger le fichier
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', filename);
	document.body.appendChild(link);

	// Déclenche le téléchargement
	link.click();

	// Nettoie les éléments créés et libère l'URL
	document.body.removeChild(link);
	URL.revokeObjectURL(url);
};

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

export function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key,
): (
	a: { [key in Key]: number | string | IOrganisation },
	b: { [key in Key]: number | string | IOrganisation },
) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

export function convertDateToDifferentFormat(date: string | Date, format = 'DD-MM-YYYY HH:mm:ss') {
	if (date) {
		return moment(date).format(format);
	} else {
		return '-';
	}
}

export function splitAndParseNumbers(inputString: string) {
	// Divise la chaîne par les virgules et supprime les espaces autour
	const splittedValues = inputString.split(',').map((value) => value.trim());

	// Convertit chaque élément en nombre et filtre les valeurs invalides
	const parsedNumbers = splittedValues
		.map((value) => parseFloat(value))
		.filter((value) => !isNaN(value));

	return parsedNumbers;
}

export function extractCoordinates(localisation: string): { latitude: number; longitude: number } {
	const coordinates = localisation?.split(',');
	if (coordinates?.length !== 2) {
		// Si la chaîne ne contient pas deux valeurs séparées par une virgule, retourner null ou gérer l'erreur selon vos besoins
		return { latitude: 0, longitude: 0 };
	}
	const latitude = parseFloat(coordinates[0].trim());
	const longitude = parseFloat(coordinates[1].trim());
	if (isNaN(latitude) || isNaN(longitude)) {
		// Si les valeurs ne sont pas des nombres valides, retourner null ou gérer l'erreur selon vos besoins
		return { latitude: 0, longitude: 0 };
	}
	return { latitude, longitude };
}

export function formatDate(date: Date, format = 'DD-MM-YYYY HH:mm:ss'): string {
	return moment(date).format(format);
}
