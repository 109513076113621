import { IHeadCellHistory, IHeadCellOrga, IHeadCellUser, IHeadCellVideo } from '../../../../types/organisations-types';

export const headCells: IHeadCellOrga[] = [
	{
		id: 'name',
		numeric: false,
		dateFormat: false,
		disablePadding: true,
		label: 'Nom',
	},
	{
		id: 'num_users',
		numeric: true,
		dateFormat: false,
		disablePadding: false,
		label: "Nbre d'utilisateurs",
	},
	{
		id: 'num_flux',
		numeric: true,
		dateFormat: false,
		disablePadding: false,
		label: 'Nbre de flux',
	},
	{
		id: ['address', 'postalCode', 'city'],
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Adresse',
	},
	{
		id: 'last_detection',
		numeric: false,
		dateFormat: true,
		disablePadding: false,
		label: 'Dernière détection',
	},
	{
		id: 'created_at',
		numeric: false,
		dateFormat: true,
		disablePadding: false,
		label: 'Date de création',
	},
];

export const headCellsUserOrga: IHeadCellUser[] = [
	{
		id: ['last_name', 'first_name'],
		numeric: false,
		dateFormat: false,
		disablePadding: true,
		label: 'Utilisateurs',
	},
	{
		id: 'email',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Email',
	},
	{
		id: 'created_at',
		numeric: false,
		dateFormat: true,
		disablePadding: false,
		label: "Date d'ajout",
	},
	{
		id: 'last_login',
		numeric: false,
		dateFormat: true,
		disablePadding: false,
		label: 'Dernière connexion',
	},
];

export const headCellsVideoOrga: IHeadCellVideo[] = [
	{
		id: 'name',
		numeric: false,
		dateFormat: false,
		disablePadding: true,
		label: 'Nom du flux',
	},
	{
		id: 'detection_type',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: "Type de détection",
	},
	{
		id: 'video_status',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: "Santé du flux",
	},
	{
		id: 'detection_date',
		numeric: false,
		dateFormat: true,
		disablePadding: false,
		label: 'Date de dernière détection',
	},
	{
		id: 'localisation',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: "Localisation",
	},
	{
		id: 'source',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Type de source',
	},
];

export const headCellsHistoryOrga: IHeadCellHistory[] = [
	{
		id: 'name',
		numeric: false,
		dateFormat: false,
		disablePadding: true,
		label: 'Nom du flux',
	},
	{
		id: 'detection_type',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Type de détection',
	},
	{
		id: 'detection_date',
		numeric: false,
		dateFormat: true,
		disablePadding: false,
		label: 'Date de détection',
	},
	{
		id: 'localisation',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Localisation',
	},
];
