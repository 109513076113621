import * as React from 'react';
import CustomDrawer from '../../../../components/common/CustomDrawer';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useFields from '../constants/fields';
import useGenerateField from '../../../../hooks/useGenerateField';

interface ICreateUpdateOrganisationProps {
	open: boolean;
	setOpen: () => void;
	data: any;
}
const CreateUpdateOrganisation: React.FC<ICreateUpdateOrganisationProps> = ({
	open,
	setOpen,
	data,
}): JSX.Element => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { fields } = useFields();
	const generateFields = useGenerateField();

	const [error, setError] = React.useState(false);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		let hasEmptyField = false;

		const formValues: Record<string, string> = {};
		formData.forEach((value, key) => {
			if (!value) {
				hasEmptyField = true;
				setError(true);
			} else {
				formValues[key] = value as string;
			}
		});

		if (!hasEmptyField) {
			if (data) {
				dispatch.organisations.update({ id: data.id, ...formValues });
			} else {
				dispatch.organisations.create(formValues);
			}
			setOpen();
		}
	};

	return (
		<CustomDrawer
			open={open}
			setOpen={setOpen}
			title={
				data
					? `${t('orga.Editer Organisation')} ${data.name} `
					: t('orga.Créer une nouvelle organisation')
			}>
			<form onSubmit={handleSubmit}>
				{fields.map((field: any) => generateFields(field, data?.[field?.name], error))}
				{error && (
					<p
						style={{
							color: '#d32e2f',
							textAlign: 'right',
							marginTop: 10,
							fontSize: 14,
						}}>
						{t('common.Tous les champs sont obligatoires.')}
					</p>
				)}
				<Button
					type='submit'
					variant='contained'
					color='primary'
					sx={{ margin: '15px 0', width: '100%' }}>
					{t('common.Sauvegarder')}
				</Button>
			</form>
		</CustomDrawer>
	);
};

export default CreateUpdateOrganisation;
