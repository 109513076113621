import { Models } from '@rematch/core';
import { auth } from './models/auth';
import { dashboard } from './models/dashboard';
import { organisations } from './models/organisations';
import { users } from './models/users';
import alerts from './models/alerts';
import { history } from './models/history';
import { flux } from './models/flux';
import { notifications } from './models/notifications';

export interface RootModel extends Models<RootModel> {
	auth: typeof auth;
	dashboard: typeof dashboard;
	organisations: typeof organisations;
	users: typeof users;
	alerts: typeof alerts;
	history: typeof history;
	flux: typeof flux;
	notifications: typeof notifications;
}
export const models: RootModel = { auth, dashboard, organisations, users, alerts, history, flux, notifications };
