import * as React from 'react';
import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useGenerateField from '../../../hooks/useGenerateField';
import useFields from '../constants/fields';
import CustomDrawer from '../../../components/common/CustomDrawer';
import { useSelectField } from '../../../hooks/useSelectField';
import { check } from 'prettier';

interface ICreateUpdateFluxProps {
	open: boolean;
	setOpen: () => void;
	data: any;
}
const CreateUpdateFlux: React.FC<ICreateUpdateFluxProps> = ({
	open,
	setOpen,
	data,
}): JSX.Element => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { fields } = useFields();
	const generateFields = useGenerateField();
	const { selectValues, handleSelectChange, resetState } = useSelectField();

	console.log('data', data)
	const [error, setError] = React.useState(false);
	const [selectedRadioItem, setSelectedRadioItem] = React.useState(data?.source ?? '');
	const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value === 'local') {
			document.getElementById('raised-button-file-local')?.click();
		}
		setSelectedRadioItem((event.target as HTMLInputElement).value);
		setSelectedFile(null);
	};

	const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			setSelectedFile(event.target.files[0]);
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		console.log(formData)
		console.log(data)
		let hasEmptyField = false;

		const formValues: Record<string, string | number[]> = {};

		if (selectedFile) formData.append('video', selectedFile);

		formData.forEach((value, key) => {

			if (key == 'serial_number') {
				if (!value) formValues[key] = '';
			}

			if (!value) {
				if (key != 'serial_number') {
					hasEmptyField = true;
					setError(true);
				}
			} else {
				if (key === 'organisations') {
					formValues[key] = (value as string).split(',').map(Number);
				} else {
					formValues[key] = value as string;
				}
			}
		});

		console.log('formValues--->', formValues)

		if (!hasEmptyField) {
			if (data) {
				dispatch.flux.update({ id: data.id, ...formValues });
			} else {
				dispatch.flux.create(formValues);
			}
			setOpen();
		}
	};

	const handleClose = () => {
		setOpen();
		resetState();
	};

	return (
		<CustomDrawer
			open={open}
			setOpen={handleClose}
			title={data ? t('flux.Editer un flux') : t('flux.Créer un nouveau flux')}>
			<form onSubmit={handleSubmit}>
				{fields.map((field: any) => {
					var errors = false;
					if (field?.name != 'serial_number') { errors = error }

					return generateFields(
						field,
						data?.[field?.name],
						errors,
						selectValues,
						handleSelectChange,
					);


				})}
				<FormControl sx={{ width: '100%' }}>
					<FormLabel id='demo-controlled-radio-buttons-group'>
						{t('flux.Choix de la source')}
					</FormLabel>
					<RadioGroup
						sx={{ gap: '30px' }}
						row
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='source'
						value={selectedRadioItem}
						onChange={handleChange}>
						<label htmlFor='raised-button-file-local' style={{ cursor: 'pointer' }}>
							<input
								accept='video/*'
								style={{
									display: 'none',
								}}
								id='raised-button-file-local'
								type='file'
								onClick={() => setSelectedRadioItem('local')}
								onChange={handleSelectFile}
							/>
							<Radio value='local' checked={selectedRadioItem === 'local'} required />
							{t('flux.Local')}
						</label>

						<FormControlLabel value='url' control={<Radio />} label='URL' />
					</RadioGroup>
				</FormControl>
				{selectedFile && (
					<Typography variant='caption'>
						Fichier sélectionné : {selectedFile.name}
					</Typography>
				)}
				{selectedRadioItem === ('url' || 'local') && (
					<TextField
						type={selectedRadioItem === 'local' ? 'file' : 'text'}
						id={`outlined-basic-${'name'}`}
						variant='outlined'
						name='url'
						required
						label='URL'
						placeholder='https://www....'
						defaultValue={data?.url}
						error={error}
						style={{ margin: '20px 0px', width: '100%' }}
					/>
				)}
				{error && (
					<p
						style={{
							color: '#d32e2f',
							textAlign: 'right',
							marginTop: 10,
							fontSize: 14,
						}}>
						{t('common.Tous les champs sont obligatoires.')}
					</p>
				)}
				<Button
					type='submit'
					variant='contained'
					color='primary'
					sx={{ margin: '15px 0', width: '100%' }}>
					{t('common.Sauvegarder')}
				</Button>
			</form>
		</CustomDrawer>
	);
};

export default CreateUpdateFlux;
