// alertModel.ts

import { createModel } from '@rematch/core';
import { IAlertInfo } from '../../types/customAlert-types';
import { RootModel } from '..';

const alerts = createModel<RootModel>()({
	state: {
		open: false,
		severity: 'success',
		message: '',
	} as IAlertInfo,
	reducers: {
		setAlert(state: IAlertInfo, payload: IAlertInfo) {
			return { ...state, ...payload };
		},
		closeAlert(state: IAlertInfo) {
			return { ...state, open: false };
		},
		resetAlert() {
			return { open: false, message: '', severity: 'success' };
		},
	},
});

export default alerts;
