import { Button } from '@mui/material';
import * as React from 'react';
import Drone404 from '../../assets/img/404.jpg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Page404 = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
			}}>
			<img src={Drone404} alt='drone' style={{ height: '50vh' }} />
			<Button onClick={() => navigate('/')}>{t("common.Page d'accueil")}</Button>
		</div>
	);
};

export default Page404;
