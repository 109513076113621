import * as React from 'react';
import Wrapper from '../../../components/layout/Wrapper/Wrapper';
import VideoPlayer from '../../gestionFlux/components/VideoPlayer';
import Localisation from '../../gestionFlux/components/Localisation';
import { useTranslation } from 'react-i18next';
import GoBack from '../../../components/common/GoBack';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../store/store';
import { extractCoordinates } from '../../../utils/helpers';
import theme from '../../../styles/theme';

const DetectionDetails = () => {
	const params = useParams();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const { notification } = useSelector((state: RootState) => state.notifications);

	const { videoId, name, organisations, localisation, image, detection_date, detection_type } =
		notification;

	React.useEffect(() => {
		dispatch.notifications.getOne(params.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	return (
		<Wrapper>
			<Stack
				direction={{ sm: 'column', md: 'row' }}
				alignItems='center'
				gap={2}
				justifyContent={{ sm: 'center', md: 'space-between', lg: 'center' }}
				style={{ position: 'relative', marginBottom: '35px' }}>
				{isMobile ? (
					<Stack sx={{ width: { xs: '100%' } }} alignItems='flex-start'>
						<GoBack title={t('notifications.Retour liste des notifications')} />
					</Stack>
				) : (
					<GoBack title={t('notifications.Retour liste des notifications')} />
				)}
				<Typography variant='h1'>{t('notifications.Détection de panne')}</Typography>
			</Stack>
			<Stack
				direction={{ sm: 'column', md: 'row' }}
				justifyContent='space-between'
				gap={2}
				style={{
					marginBottom: 40,
				}}>
				<Box sx={{ width: '100%' }}>
					<VideoPlayer
						videoId={videoId}
						inLive={false}
						data={{ image, date: detection_date, type: detection_type }}
					/>
					<Stack direction='row' sx={{ marginTop: '20px' }}>
						<Typography style={{ fontSize: '16px', fontWeight: 700 }}>
							{t('flux.Nom du flux')} :
						</Typography>
						<Typography sx={{ marginLeft: '10px' }}>{name}</Typography>
					</Stack>
				</Box>
				<Box sx={{ width: '100%' }}>
					{localisation && (
						<Localisation coordinates={extractCoordinates(localisation)} />
					)}
					<Stack direction='row' sx={{ marginTop: '20px' }}>
						<Typography style={{ fontSize: '16px', fontWeight: 700 }}>
							{t('orga.Organisation')} :
						</Typography>
						<Typography sx={{ marginLeft: '10px' }}>
							{organisations?.map((orga) => orga.name)?.join(', ')}
						</Typography>
					</Stack>
				</Box>
			</Stack>
		</Wrapper>
	);
};

export default DetectionDetails;
