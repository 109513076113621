import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import contents from '../../../routes/routes';

const ContentRoutes = () => {
	return (
		<Routes>
			{contents.map((page) => (
				<Route key={page.path} {...page} />
			))}
			<Route path='*' element={<Navigate to='/404' replace />} />
		</Routes>
	);
};

export default ContentRoutes;
