import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { Button, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import theme from '../../styles/theme';
import GoBack from './GoBack';

interface ICustomDrawerProps {
	open: boolean;
	setOpen: () => void;
	title: string;
	text?: string;
	cancelText?: string;
	children: React.ReactNode;
	type?: string;
	goBack?: Function;
}
const CustomDrawer: React.FC<ICustomDrawerProps> = ({
	open,
	setOpen,
	title,
	text,
	cancelText = 'Annuler',
	goBack,
	children,
}): JSX.Element => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Drawer anchor='right' open={open} onClose={setOpen} sx={{ zIndex: 1300 }}>
			<Box role='presentation' sx={{ width: { xs: '100vw', sm: 430 } }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: '20px',
					}}>
					<Typography
						variant='h2'
						sx={{
							fontSize: 22,
							fontWeight: 700,
						}}>
						{title}
					</Typography>
					{isMobile && (
						<IconButton onClick={setOpen}>
							<Close />
						</IconButton>
					)}
				</Box>
				<Divider />
				{goBack && (
					<GoBack
						title=''
						path={goBack}
						style={{
							position: 'absolute',
							left: 10,
							top: { md: 80 },
							borderColor: 'transparent',
							backgroundColor: 'transparent',

						}}
					/>
				)}
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						padding: '30px 60px',
					}}>
					{text && (
						<Typography
							variant='body1'
							sx={{
								textAlign: 'center',
								fontSize: 22,
								fontWeight: 600,
								margin: '18px 0',
							}}>
							{text}
						</Typography>
					)}

					{children}

					<Button variant='outlined' onClick={setOpen}>
						{t(`common.${cancelText}`)}
					</Button>
				</Box>
			</Box>
		</Drawer>
	);
};

export default CustomDrawer;
