import { Badge, Chip, Avatar, Typography, Stack } from '@mui/material';
import {
	LocalFireDepartmentOutlined,
	CheckOutlined,
	CameraOutlined,
	DirectionsRunOutlined,
	WhatshotOutlined,
	DirectionsCar,
	Videocam,
	VideocamOff,
} from '@mui/icons-material';
import { IDetectionType, IVideoStatusType } from '../types/flux-types';
import theme from '../styles/theme';
import { capitalizeFirstLetter } from './helpers';

export const displayDetectionType = (type: IDetectionType) => {
	switch (type) {
		case 'fire':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<LocalFireDepartmentOutlined
									sx={{ color: theme.palette.success.contrastText }}
								/>
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);
		case 'smoke':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<WhatshotOutlined
									sx={{ color: theme.palette.success.contrastText }}
								/>
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);

		case 'drone':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<CameraOutlined
									sx={{ color: theme.palette.success.contrastText }}
								/>
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);

		case 'ok':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<CheckOutlined sx={{ color: theme.palette.success.contrastText }} />
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);
		case 'human':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<DirectionsRunOutlined
									sx={{ color: theme.palette.success.contrastText }}
								/>
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);
		case 'vehicle':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<DirectionsCar
									sx={{ color: theme.palette.success.contrastText }}
								/>
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);
		default:
			return null;
	}
};
export const displayVideoStatus = (type: IVideoStatusType) => {

	switch (type) {
		case 'NOT_PLAYING':
			return (
				<Stack direction='row' gap={1} alignItems="center">
					<Videocam htmlColor='#9E9E9E' sx={{ width: '20px', height: '20px' }} />
					<Typography>Off</Typography>
				</Stack>
			);
		case 'PLAYING':
			return (
				<Stack direction='row' gap={1} alignItems="center">
					<VideocamOff htmlColor='#9E9E9E' sx={{ width: '20px', height: '20px' }} />
					<Typography color={theme.palette.success.main}>On</Typography>
				</Stack>
			);

		case 'PROBLEM':
			return (
				<Stack direction='row' gap={1} alignItems="center">
					<VideocamOff htmlColor='#9E9E9E' sx={{ width: '20px', height: '20px' }} />
					<Typography color={theme.palette.error.main}>Erreur</Typography>
				</Stack>
			);

		default:
			return null;
	}
};
