import * as React from 'react';
import DataTable from '../../../components/datatable/Datatable';
import Wrapper from '../../../components/layout/Wrapper/Wrapper';
import { Box, Button, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import AddIcon from '@mui/icons-material/Add';
import CreateUpdateUser from '../users/components/CreateUpdate';
import { IUser } from '../../../types/user-types';
import DeleteDrawer from '../../../components/common/DeleteDrawer';
import { headCells } from './constants/columns';
import { CrudOptions } from '../../../types/datatable-types';
import theme from '../../../styles/theme';

const UsersList = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { users } = useSelector((state: RootState) => state.users);

	const [openDeleteDrawer, setOpenDeleteDrawer] = React.useState(false);
	const [openCreateUpdateUserDrawer, setOpenCreateUpdateUserDrawer] = React.useState(false);

	const [selectedUser, setSelectedUser] = React.useState<IUser>();

	React.useEffect(() => {
		dispatch.users.getAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const crudHandlers: CrudOptions = {
		handleEdit: (id: number) => {
			setOpenCreateUpdateUserDrawer(!openCreateUpdateUserDrawer);
			const user = users.find((user) => user.id === id);
			setSelectedUser(user);
		},
		handleDelete: (id: number) => {
			setOpenDeleteDrawer(true);
			const user = users.find((user) => user.id === id);
			setSelectedUser(user);
		},
	};

	const handleDelete = () => {
		dispatch.users.delete(selectedUser?.id).then(() => {
			setOpenDeleteDrawer(false);
		});
	};

	return (
		<Wrapper>
			<Stack direction='row' justifyContent='space-between' sx={{ marginBottom: '35px' }}>
				<Typography variant='h1'>{t('users.Liste des utilisateurs')}</Typography>

				{isMobile ? (
					<Box>
						<IconButton
							aria-label='create'
							onClick={() =>
								setOpenCreateUpdateUserDrawer(!openCreateUpdateUserDrawer)
							}
							sx={{
								backgroundColor: theme.palette.primary.main,
								border: `1px solid ${theme.palette.primary.main}`,
								borderRadius: '4px',
								'&.MuiIconButton-root:hover': {
									backgroundColor: '#F6B8A2',
									border: `1px solid #F6B8A2`,
								},
							}}>
							<AddIcon sx={{ color: '#fff' }} />
						</IconButton>
					</Box>
				) : (
					<Button
						onClick={() => setOpenCreateUpdateUserDrawer(!openCreateUpdateUserDrawer)}
						sx={{ height: '40px' }}
						variant='contained'
						startIcon={<AddIcon />}>
						{t('users.Créer un nouvel utilisateur')}
					</Button>
				)}
			</Stack>
			<DataTable
				columns={headCells}
				data={users}
				defaultSortId='name'
				crudOptions={crudHandlers}
				translation='users'
			/>
			{selectedUser && (
				<DeleteDrawer
					open={openDeleteDrawer}
					setOpen={() => {
						setSelectedUser(undefined);
						setOpenDeleteDrawer(false);
					}}
					handleDelete={handleDelete}
					data={{
						name: `${selectedUser?.first_name} ${selectedUser?.last_name}`,
						id: selectedUser.id,
					}}
				/>
			)}

			<CreateUpdateUser
				open={openCreateUpdateUserDrawer}
				setOpen={() => {
					setSelectedUser(undefined);
					setOpenCreateUpdateUserDrawer(false);
				}}
				isCreating={!selectedUser}
				data={selectedUser}
				type={selectedUser ? 'updateFull' : 'createFull'}
			/>
		</Wrapper>
	);
};

export default UsersList;
