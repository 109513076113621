import * as React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { DateRange } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import theme, { chooseLanguage } from '../../styles/theme';
import moment from 'moment';
import { Box, IconButton, Stack, useMediaQuery } from '@mui/material';
interface ICustomDatePickerProps {
	date: string | null;
	setDate: (date: string | null) => void;
	placeholder?: string;
}
const CustomDatePicker: React.FC<ICustomDatePickerProps> = ({
	date,
	setDate,
	placeholder,
}): JSX.Element => {
	const { t, i18n } = useTranslation();

	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [open, setOpen] = React.useState(false);

	return (
		<LocalizationProvider
			dateAdapter={AdapterMoment}
			adapterLocale={i18n.language}
			localeText={
				chooseLanguage().components.MuiLocalizationProvider.defaultProps.localeText
			}>
			<Stack direction={'row'} gap={2}>
				{isMobile && (
					<Box>
						<IconButton edge='end' onClick={() => setOpen(true)}>
							<DateRange />
						</IconButton>
					</Box>
				)}

				<DatePicker
					className='custom-date-picker'
					slots={{ openPickerIcon: DateRange }}
					sx={{
						width: isMobile ? date ? '180px' : '0px' : date ? '230px' : '45px',
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: date ? '' : 'transparent',
						},
						'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
							borderColor: date ? '' : 'transparent',
						},
						'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
							borderColor: date ? '' : 'transparent',
						},
					}}
					slotProps={{
						inputAdornment: {
							position: 'start',
							hidden: isMobile,
						},
						textField: {
							hidden: date ? true : false,
							size: 'small',
							placeholder: placeholder ? t(`common.${placeholder}`) : ' ',
						},
						field: { clearable: true, onClear: () => setDate(null) } as any,
					}}
					format='DD/MM/YYYY'
					onChange={(newValue) =>
						setDate(newValue !== null ? newValue.format('YYYY-MM-DD') : null)
					}
					maxDate={moment()}
					localeText={
						chooseLanguage().components.MuiLocalizationProvider.defaultProps.localeText
					}
					open={open}
					onClose={() => setOpen(false)}
					onOpen={() => setOpen(true)}
				/>
			</Stack>
		</LocalizationProvider>
	);
};
export default CustomDatePicker;
