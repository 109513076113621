import React from 'react';
import {
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	TextareaAutosize,
	Autocomplete,
	OutlinedInput,
	InputAdornment,
	IconButton,
	Checkbox,
	ListItemText,
	Switch,
	FormLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { SelectValues } from './useSelectField';

type FieldType = 'numeric' | 'text' | 'textarea' | 'select' | 'autocomplete' | 'password' | 'switch' | 'select2';

export interface FieldConfig {
	type: FieldType;
	name: string;
	label: string;
	placeholder?: string;
	options?: { label: string; value: string | number }[];
	style?: React.CSSProperties;
	multiple?: boolean;
	required?: boolean;
	checked?: boolean;
}

const useGenerateField = () => {
	const { t } = useTranslation();

	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		config: FieldConfig,
		defaultValue: string | number | (string | number)[] | any,
		error: boolean,
		selectValues?: SelectValues,
		handleSelectChange?: Function,


	) => {
		const { type, name, label, placeholder, options, style, multiple } = config;
		switch (type) {
			case 'numeric':
				return (
					<TextField
						key={name}
						type='number'
						name={name}
						label={label}
						placeholder={placeholder}
						error={error}
						defaultValue={defaultValue}
						style={{ marginBottom: '20px', width: '100%', ...style }}
					/>
				);
			case 'switch':
				return (<>
					<FormLabel id='demo-controlled-radio-buttons-group' >
						{t(label)}
					</FormLabel>
					<Switch
						key={name}
						checked={defaultValue}
						name={name}
					/></>
				);
			case 'text':
				return (
					<TextField
						key={name}
						type='text'
						id={`outlined-basic-${name}`}
						variant='outlined'
						name={name}
						label={label}
						autoComplete={name}
						placeholder={placeholder}
						error={error}
						defaultValue={defaultValue}
						style={{ marginBottom: '20px', width: '100%', ...style }}
					/>
				);
			case 'textarea':
				return (
					<TextareaAutosize
						key={name}
						name={name}
						aria-label={label}
						placeholder={placeholder}
						defaultValue={defaultValue as string}
						style={{ marginBottom: '20px', width: '100%', ...style }}
					/>
				);
			case 'select':
				const defaultSelectValue = Array.isArray(defaultValue)
					? defaultValue.map((val) => val.id)
					: [];

				return (
					<FormControl
						// size='small'
						key={name}
						style={{ marginBottom: '20px', width: '100%', ...style }}
					>
						<InputLabel error={error} id='demo-simple-select-label'>
							{label}
						</InputLabel>
						<Select
							labelId={`select-label-${name}`}
							id={`select-${name}`}
							name={name}
							label={label}
							error={error}
							multiple={multiple}
							value={(selectValues && selectValues[name]) || (multiple ? defaultSelectValue : [defaultValue])}
							onChange={(e) =>
								handleSelectChange && handleSelectChange(
									name,
									e.target.value as string | number | (string | number)[],
								)
							}
							placeholder={placeholder}
							renderValue={(selected) => {
								if (Array.isArray(selected)) {
									return selected
										.map((value) => {
											const option = options?.find(
												(opt) => opt.value === value,
											);
											return option ? option.label : '';
										})
										.join(', ');
								}
								const option = options?.find((opt) => opt.value === selected);
								return option ? option.label : '';
							}}>
							<MenuItem value='' disabled>
								<em>{placeholder}</em>
							</MenuItem>
							{options &&
								options.map(({ label, value }, index) => (
									<MenuItem key={index} value={value} >
										{multiple ? (
											<Checkbox
												checked={(
													((selectValues && selectValues[name] as (string | number)[])) ||
													defaultSelectValue ||
													[]
												).includes(value as string | number)}
											/>
										) : null}
										<ListItemText primary={label} />
									</MenuItem>
								))}
						</Select>
					</FormControl>
				);
			case 'select2':
				const defaultSelectValue2 = Array.isArray(defaultValue)
					? defaultValue.map((val) => val.id)
					: [];

				return (
					<FormControl
						// size='small'
						key={name}
					>
						<InputLabel error={error} id='demo-simple-select-label'>
							{label}
						</InputLabel>
						<Select
							labelId={`select-label-${name}`}
							id={`select-drone`}
							name={name}
							label={label}
							error={error}
							multiple={multiple}
							style={{ ...style }}
							value={(selectValues && selectValues[name]) || (multiple ? defaultSelectValue2 : [defaultValue])}
							onChange={(e) =>
								handleSelectChange && handleSelectChange(
									name,
									e.target.value as string | number | (string | number)[],
								)
							}
							placeholder={placeholder}
							renderValue={(selected) => {
								if (Array.isArray(selected)) {
									return selected
										.map((value) => {
											const option = options?.find(
												(opt) => opt.value === value,
											);
											return option ? option.label : '';
										})
										.join(', ');
								}
								const option = options?.find((opt) => opt.value === selected);
								return option ? option.label : '';
							}}>
							<MenuItem value='' disabled>
								<em>{placeholder}</em>
							</MenuItem>
							{options &&
								options.map(({ label, value }, index) => (
									<MenuItem key={index} value={value} >
										{multiple ? (
											<Checkbox
												checked={(
													((selectValues && selectValues[name] as (string | number)[])) ||
													defaultSelectValue ||
													[]
												).includes(value as string | number)}
											/>
										) : null}
										<ListItemText primary={label} />
									</MenuItem>
								))}
						</Select>
					</FormControl>
				);

			case 'autocomplete':
				return (
					<Autocomplete
						key={name}
						style={{ marginBottom: '25px', width: '100%', ...style }}
						freeSolo
						options={options || []}
						renderInput={(params) => (
							<TextField
								{...params}
								name={name}
								label={label}
								placeholder={placeholder}
								error={error}
								defaultValue={defaultValue}
							/>
						)}
					/>
				);
			case 'password':
				return (
					<FormControl
						variant='outlined'
						style={{ marginBottom: '20px', width: '100%', ...style }}>
						<InputLabel error={error} htmlFor='outlined-adornment-password'>
							{t(label)}
						</InputLabel>
						<OutlinedInput
							name={name}
							error={error}
							id='outlined-adornment-password'
							type={showPassword ? 'text' : 'password'}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge='end'>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label={t(label)}
							autoComplete='current-password'
						/>
					</FormControl>
				);

			default:
				throw new Error(`Invalid field type: ${type}`);
		}
	};
};

export default useGenerateField;
