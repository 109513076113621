import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import * as React from 'react';
import logoDark from '../../assets/img/logo-dark.png';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authPages, menu } from '../../routes/menu';
import theme from '../../styles/theme';
import background from '../../assets/img/bg.jpeg';

const ForgetPassword = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [email, setEmail] = React.useState('');
	const [error, setError] = React.useState<string | null>(null);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		try {
			dispatch.auth
				.resetPassword({ email })
				.then(({ status, error }: { status: number; error: string }) => {
					if (status === 200) {
						navigate(`../${menu.dashboard.path}`);
					} else {
						setError(error);
					}
				});
		} catch (error: any) {
			setError(error.message);
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: { xs: '80vh', md: '100vh' },
				backgroundColor: {
					md: theme.palette.text.primary,
				},
			}}>
			<Box
				sx={{
					display: { xs: 'none', sm: 'block' },
					position: 'absolute',
					height: '100vh',
					width: '100vw',
					backgroundColor: {
						md: theme.palette.text.primary,
					},
					backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${background})`,
					backgroundSize: 'cover',
					filter: 'blur(7px)',
					zIndex: 1,
				}}
			/>
			<Paper
				sx={{
					height: 570,
					width: 360,
					padding: '20px 30px',
					display: 'flex',
					flexDirection: 'column',
					zIndex: 2,
					'&.MuiPaper-root:hover': {
						boxShadow: {
							xs: 'none',
							md: '0px 4px 24px 0px #F18A65, 0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14)',
						},
					},
				}}>
				<img
					src={logoDark}
					alt='logo-fire-eagle'
					style={{ width: '60%', margin: '20px auto 0px auto' }}
				/>
				<h1 style={{ marginBottom: 0, textAlign: 'center' }}>{t('login.Bienvenue')}</h1>
				<p style={{ textAlign: 'center' }}>
					{t('login.Veuillez saisir votre identifiant')}
				</p>

				<form onSubmit={handleSubmit} style={{ display: 'contents' }}>
					<TextField
						sx={{ marginBottom: 2 }}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						id='outlined-basic'
						label={t('login.Identifiant')}
						placeholder='Ex: john.doe@test.com'
						variant='outlined'
						error={Boolean(error)}
						autoComplete='email'
					/>

					{error && (
						<Typography
							sx={{
								color: theme.palette.error.main,
								marginTop: '5px',
								textAlign: 'right',
							}}>
							{error}
						</Typography>
					)}

					<Button
						type='submit'
						variant='contained'
						disabled={!email}
						sx={{ marginBottom: 2 }}>
						{t('login.Envoyer')}
					</Button>
					<Button
						type='submit'
						variant='outlined'
						onClick={() => navigate(`../${authPages.login.path}`)}>
						{t('login.Retour')}
					</Button>
				</form>
			</Paper>
		</Box>
	);
};

export default ForgetPassword;
