import { createModel } from '@rematch/core';
import { RootModel } from '..';
import { API_ENDPOINT, httpSecured } from '../../services/http';
import { IOrga, IOrganisation } from '../../types/organisations-types';

export const organisations = createModel<RootModel>()({
	state: {
		organisations: [] as IOrganisation[],
		organisation: {} as IOrga,
	},
	reducers: {
		set: (state, payload, subject) => {
			return { ...state, [subject]: payload };
		},
	},
	effects: (dispatch) => ({
		async getAll() {
			try {
				const { data } = await httpSecured.get(API_ENDPOINT.ORGANISATION.GET_ORGANISATIONS);
				if (data) {
					dispatch.organisations.set(data, 'organisations');
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async getOne(id: number) {
			try {
				const { data } = await httpSecured.get(
					`${API_ENDPOINT.ORGANISATION.GET_ORGANISATION}${id}`,
				);
				if (data) {
					dispatch.organisations.set(data, 'organisation');
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async create(payload) {
			try {
				const { data } = await httpSecured.post(
					API_ENDPOINT.ORGANISATION.CREATE_ORGANISATION,
					payload,
				);
				if (data) {
					dispatch.alerts.setAlert({
						open: true,
						severity: 'success',
						message: "L'organisation a été ajoutée avec succès.",
					});
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: "Une erreur est survenue lors de la création de l'organisation.",
				});
				return false;
			}
		},
		async update(payload) {
			try {
				const { data } = await httpSecured.put(
					`${API_ENDPOINT.ORGANISATION.UPDATE_ORGANISATION}${payload.id}/`,
					payload,
				);
				if (data) {
					dispatch.alerts.setAlert({
						open: true,
						severity: 'success',
						message: "L'organisation a été modifié avec succès.",
					});
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de la modification de l'organisation.",
				});
			}
		},
		async delete(id) {
			try {
				await httpSecured.delete(`${API_ENDPOINT.ORGANISATION.DELETE_ORGANISATION}${id}`);

				dispatch.alerts.setAlert({
					open: true,
					severity: 'success',
					message: "L'organisation a été supprimée avec succès.",
				});
				this.getAll();
				return true;
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de la suppression de l'organisation.",
				});
				return false;
			}
		},
		async addUserToOrganisation(payload) {
			try {
				const { id, email } = payload;
				const { data } = await httpSecured.post(
					`${API_ENDPOINT.ORGANISATION.ADD_USER_TO_ORGANISATION}${id}/${email}/`,
				);
				if (data) {
					dispatch.alerts.setAlert({
						open: true,
						severity: 'success',
						message: "L'utilisateur a été ajouté à l'organisation avec succès.",
					});
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de l'ajout d'utilisateur.",
				});
				return false;
			}
		},
		async createUserToOrganisation(payload) {
			try {
				const { id, formValues } = payload;
				const { data } = await httpSecured.post(
					`${API_ENDPOINT.USER.SIGNUP_ORGANISATION}${id}/`,
					formValues,
				);
				if (data) {
					dispatch.alerts.setAlert({
						open: true,
						severity: 'success',
						message: "L'utilisateur a été créé et ajouté à l'organisation avec succès.",
					});
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de la création de l'utilisateur.",
				});
				return false;
			}
		},
		async deleteFromOrganisation(payload) {
			const { id, email } = payload;
			try {
				await httpSecured.post(
					`${API_ENDPOINT.ORGANISATION.REMOVE_USER_FROM_ORGANISATION}${id}/${email}/`,
				);

				dispatch.alerts.setAlert({
					open: true,
					severity: 'success',
					message: "L'utilisateur a été supprimé de l'organisation avec succès.",
				});
				this.getOne(id);
				return true;
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de la suppression de l'utilisateur.",
				});
				return false;
			}
		},
	}),
});
