import { IHeadCellNotifications } from '../../../types/notifications-types';

export const headCells: IHeadCellNotifications[] = [
	{
		id: 'name',
		numeric: false,
		dateFormat: false,
		disablePadding: true,
		label: 'Nom du flux',
	},
	{
		id: 'detection_type',
		numeric: true,
		dateFormat: false,
		disablePadding: false,
		label: 'Dernière détection',
	},
	{
		id: 'detection_date',
		numeric: true,
		dateFormat: true,
		disablePadding: false,
		label: 'Date de détection',
	},
	{
		id: 'localisation',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Localisation',
	},
	{
		id: 'organisations',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		populate: 'name',
		label: 'Organisation',
	},
];
