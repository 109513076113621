import React from 'react';
import { Snackbar, Alert, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { VisibilityOutlined } from '@mui/icons-material';
import { authPages } from '../../routes/menu';

const CustomAlert = (): JSX.Element => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { t } = useTranslation();

	const { open, severity, message, action } = useSelector((state: RootState) => state.alerts);

	const handleAlertClose = () => {
		dispatch.alerts.closeAlert();
		setTimeout(() => {
			dispatch.alerts.resetAlert();
		}, 500);
	};

	return (
		<Snackbar
			sx={{
				top: [null, null, pathname === authPages.login.path ? '20px' : '135px'],
				right: [null, null, '18px'],
			}}
			open={open}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			autoHideDuration={!action ? 10000 : 100000}
			onClose={action ? () => null : handleAlertClose}>
			<Alert
				severity={severity}
				onClose={handleAlertClose}
				action={
					action ? (
						<IconButton
							sx={{ paddingTop: 0 }}
							color='inherit'
							size='small'
							onClick={() => {
								navigate(action);
								handleAlertClose();
							}}>
							<VisibilityOutlined />
						</IconButton>
					) : null
				}>
				{action ? message : t(`alertes.${message}`)}
			</Alert>
		</Snackbar>
	);
};

export default CustomAlert;
