import {
	Box,
	Button,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	Typography,
	useMediaQuery,
} from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Wrapper from '../../components/layout/Wrapper/Wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import DashboardCard from './components/DashboardCard';

import FluxCard from './components/FluxCard';
import {
	PlayCircleOutlined,
	WarningAmberOutlined,
	GroupOutlined,
	FileDownloadOutlined,
	FolderOffOutlined,
} from '@mui/icons-material';
import { IKpis } from '../../types/dashboard-types';
import { downloadCSVFile } from '../../utils/helpers';
import theme from '../../styles/theme';
import CustomDatePicker from '../../components/common/DatePicker';

const Dashboard = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { kpis } = useSelector((state: RootState) => state.dashboard);

	const [selectedOrganisation, setSelectedOrganisation] = React.useState('All');
	const [organisations, setOrganisations] = React.useState<IKpis[]>(kpis);
	const [date, setDate] = React.useState<string | null>(null);

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	React.useEffect(() => {
		dispatch.dashboard.getKpis(date).then((res: IKpis[]) => setOrganisations(res));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	const handleFilterOrganisation = (event: SelectChangeEvent) => {
		setSelectedOrganisation(event.target.value);
		if (event.target.value === 'All') {
			setOrganisations(kpis);
		} else {
			setOrganisations(kpis.filter((kpi) => kpi.name === event.target.value));
		}
	};

	const handleDownloadCSV = () => {
		dispatch.dashboard.exportCSV().then((res: string) => {
			downloadCSVFile(res, 'moncsv.csv');
		});
	};

	return (
		<Wrapper>
			<Stack direction='row' justifyContent='space-between'>
				<Typography variant='h1'>{t('dashboard.Tableau de bord')}</Typography>
				<Stack
					direction={{ xs: date ? 'column-reverse' : 'row', sm: 'column', md: 'row' }}
					alignItems={{ xs: date ? 'flex-end' : 'center' }}
					sx={{ marginLeft: 2 }}
					gap={2}>
					<CustomDatePicker date={date} setDate={setDate} />

					{isMobile ? (
						<Box>
							<IconButton
								aria-label='edit'
								onClick={handleDownloadCSV}
								sx={{
									border: `1px solid ${theme.palette.secondary.main}`,
									borderRadius: '4px',
									height: '40px',
									width: '40px',
								}}>
								<FileDownloadOutlined />
							</IconButton>
						</Box>
					) : (
						<Button
							variant='outlined'
							sx={{ maxWidth: '220px', width: '100%' }}
							startIcon={<FileDownloadOutlined />}
							onClick={handleDownloadCSV}>
							{t('common.Exporter csv.')}
						</Button>
					)}

					{kpis?.length > 1 && (
						<FormControl
							sx={{
								display: { xs: 'none', sm: 'flex' },
								
								minWidth: '220px',
							}}
							size='small'>
							<Select
								labelId='demo-select-small-label'
								id='demo-select-small'
								value={selectedOrganisation}
								defaultValue={'All'}
								onChange={handleFilterOrganisation}>
								<MenuItem value={'All'}>
									{t('dashboard.Tous les organisations')}
								</MenuItem>
								{kpis?.map(({ name }, index) => {
									return (
										<MenuItem key={index} value={name}>
											{name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					)}
				</Stack>
			</Stack>
			{kpis?.length > 1 && (
				<FormControl
					sx={{ display: { xs: 'flex', sm: 'none' }, minWidth: '220px', marginTop: 2 }}
					size='small'>
					<Select
						labelId='demo-select-small-label'
						id='demo-select-small'
						value={selectedOrganisation}
						defaultValue={'All'}
						onChange={handleFilterOrganisation}>
						<MenuItem value={'All'}>{t('dashboard.Tous les organisations')}</MenuItem>
						{kpis?.map(({ name }, index) => {
							return (
								<MenuItem key={index} value={name}>
									{name}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			)}
			{organisations ? (
				organisations.map(
					(
						{ name, flux_number, nbdetection, users_number, diffdetection, flux_kpi },
						index,
					) => {
						return (
							<Box key={index} style={{ marginBottom: 40, marginTop: '35px' }}>
								<Typography variant='h2' sx={{ marginBottom: '18px' }}>
									{t('dashboard.Organisation')} {name}
								</Typography>
								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									alignItems={{ xs: 'center', sm: '' }}
									spacing={2}>
									<DashboardCard
										title='Nombre de Flux'
										number={flux_number}
										icon={<PlayCircleOutlined color='primary' />}
									/>
									<DashboardCard
										title='Nombre de detection'
										number={nbdetection}
										stat={diffdetection}
										icon={<WarningAmberOutlined color='primary' />}
									/>
									<DashboardCard
										title="Nombre d'utilisateurs"
										number={users_number}
										icon={<GroupOutlined color='primary' />}
									/>
								</Stack>
								<Stack
									direction='row'
									spacing={2}
									style={{
										marginTop: '30px',
										padding: '0 1px 10px 1px',
										overflowX: 'auto',
									}}>
									{flux_kpi?.map(({ flux_name, detection }, index) => {
										return (
											<Box key={index}>
												<Typography
													variant='h3'
													color='text.secondary'
													sx={{
														marginBottom: '10px',
													}}>
													{t('dashboard.Flux')} {flux_name}
												</Typography>
												<FluxCard data={detection} />
											</Box>
										);
									})}
								</Stack>
							</Box>
						);
					},
				)
			) : (
				<Stack
					textAlign='center'
					p={3}
					alignContent='center'
					alignItems='center'
					justifyContent='center'
					sx={{
						height: '60vh',
						alignItems: 'center',
						fontWeight: 400,
					}}>
					<FolderOffOutlined
						sx={{
							height: 40,
							width: 40,
							marginBottom: '10px',
						}}
					/>
					{t('common.Aucune donnée disponible avec la date sélectionnée')}
				</Stack>
			)}
		</Wrapper>
	);
};

export default Dashboard;
