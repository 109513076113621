import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Wrapper from '../../components/layout/Wrapper/Wrapper';
import { Stack, TablePagination, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import DataTable from '../../components/datatable/Datatable';
import { headCells } from './constants/columns';
import {
	FolderOffOutlined,
	FormatListBulletedOutlined,
	GridViewOutlined,
} from '@mui/icons-material';
import NotificationCard from './components/Card';
import { CrudOptions } from '../../types/datatable-types';
import { useNavigate } from 'react-router-dom';
import { notificationsPages } from '../../routes/menu';

const Notifications = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { notifications } = useSelector((state: RootState) => state.notifications);
	const [choosenView, setChoosenView] = React.useState('grid');

	const handleChangeView = (e: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
		setChoosenView(value);
	};

	const crudHandlers: CrudOptions = {
		handleSee: (id: number) => {
			navigate(`../${notificationsPages.read.path}/${id}`);
			dispatch.notifications.setToRead(id);
		},
	};

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const allUnreadNotifications = notifications.filter((notification) => !notification.read);
	const allReadNotifications = notifications.filter((notification) => notification.read);

	const readNotificationsPerPage = allReadNotifications.slice(
		page * rowsPerPage,
		page * rowsPerPage + rowsPerPage,
	);

	return (
		<Wrapper>
			<Stack direction='row' justifyContent='space-between' style={{ marginBottom: '35px' }}>
				<Typography variant='h1' textAlign='center'>
					{t('notifications.Notifications')}
				</Typography>

				<ToggleButtonGroup exclusive onChange={handleChangeView}>
					<ToggleButton value='table' selected={choosenView === 'table'} color='primary'>
						<FormatListBulletedOutlined />
					</ToggleButton>

					<ToggleButton value='grid' selected={choosenView === 'grid'} color='primary'>
						<GridViewOutlined />
					</ToggleButton>
				</ToggleButtonGroup>
			</Stack>
			{notifications.length > 0 ? (
				choosenView === 'table' ? (
					<DataTable
						columns={headCells}
						data={notifications}
						defaultSortId='read'
						crudOptions={crudHandlers}
						translation='flux'
					/>
				) : (
					<>
						{allUnreadNotifications?.length > 0 && (
							<>
								<Typography variant='h4'>
									{t('notifications.Notifications non lues')}
								</Typography>
								{allUnreadNotifications.map((notification, index) => {
									return <NotificationCard data={notification} key={index} />;
								})}
							</>
						)}
						{readNotificationsPerPage.length > 0 && (
							<>
								<Typography variant='h4'>
									{t('notifications.Notifications lues')}
								</Typography>
								{readNotificationsPerPage.map((notification, index) => {
									return <NotificationCard data={notification} key={index} />;
								})}
							</>
						)}
						<TablePagination
							rowsPerPageOptions={[5, 10]}
							component='div'
							count={allReadNotifications?.length}
							page={page}
							onPageChange={handleChangePage}
							rowsPerPage={rowsPerPage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage={t('common.rowPerPage')}
						/>
					</>
				)
			) : (
				<Stack
					textAlign='center'
					p={3}
					sx={{
						alignItems: 'center',
						fontWeight: 400,
					}}>
					<FolderOffOutlined
						sx={{
							height: 40,
							width: 40,
							marginBottom: '10px',
						}}
					/>
					{t('notifications.Aucune notification disponible')}
				</Stack>
			)}
		</Wrapper>
	);
};

export default Notifications;
