import { IHeadCellFlux } from "../../../types/flux-types";

export const headCells: IHeadCellFlux[] = [
	{
		id: 'name',
		numeric: false,
		dateFormat: false,
		disablePadding: true,
		label: 'Nom du flux',
	},
	{
		id: 'detection_type',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Dernière détection',
	},
	{
		id: 'video_status',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Santé du flux',
	},
	{
		id: 'detection_date',
		numeric: true,
		dateFormat: true,
		disablePadding: false,
		label: 'Date de détection',
	},
	{
		id: 'localisation',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Localisation',
	},
	{
		id: 'source',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Type de source',
	},{
		id: 'camera_type',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Type de caméra',
	},
];
