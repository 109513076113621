import { useEffect, useRef, useState } from 'react';

interface WebSocketOptions {
  url: string;
  onOpen?: () => void;
  onClose?: () => void;
  onMessage?: (event: MessageEvent) => void;
}

const useWebSocket = ({ url, onOpen, onClose, onMessage }: WebSocketOptions) => {
  const socketRef = useRef<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const isSecureConnection = window.location.protocol === 'https:';
  const protocol = isSecureConnection ? 'wss://' : 'ws://';

  useEffect(() => {
    const socket = new WebSocket(`${protocol}${process.env.REACT_APP_URL_WEBSOCKET}/ws/${url}/`);
    socketRef.current = socket;

    socket.onopen = () => {
      setIsConnected(true);
      onOpen && onOpen();
    };

    socket.onclose = () => {
      setIsConnected(false);
      onClose && onClose();
    };

    socket.onmessage = (event) => {
      onMessage && onMessage(event);
    };

    return () => {
      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);



  return { isConnected };
};

export default useWebSocket;
