import * as React from 'react';
import Wrapper from '../../../../components/layout/Wrapper/Wrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Box, Button, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GoBack from '../../../../components/common/GoBack';
import DataTable from '../../../../components/datatable/Datatable';
import { headCellsHistoryOrga, headCellsUserOrga, headCellsVideoOrga } from '../constants/columns';
import { menu } from '../../../../routes/menu';
import CreateUpdateUser from '../../users/components/CreateUpdate';
import { IOrgaUser } from '../../../../types/organisations-types';
import DeleteDrawer from '../../../../components/common/DeleteDrawer';
import { CrudOptions } from '../../../../types/datatable-types';
import theme from '../../../../styles/theme';
import { Add } from '@mui/icons-material';
import CreateUpdateFlux from '../../../gestionFlux/components/CreateUpdate';
import { IVideo } from '../../../../types/flux-types';

const OrganisationDetails = () => {
	const { t } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const { organisation } = useSelector((state: RootState) => state.organisations);
	const { flux } = useSelector((state: RootState) => state.flux);

	const [type, setType] = React.useState('create');
	const [openDeleteDrawer, setOpenDeleteDrawer] = React.useState(false);
	const [openCreateUserDrawer, setOpenCreateUserDrawer] = React.useState(false);
	const [selectedUser, setSelectedUser] = React.useState<IOrgaUser>();

	const [selectedFlux, setSelectedFlux] = React.useState<IVideo>();
	const [openDeleteDrawerFlux, setOpenDeleteDrawerFlux] = React.useState(false);
	const [openCreateUpdateFluxDrawer, setOpenCreateUpdateFluxDrawer] = React.useState(false);

	React.useEffect(() => {
		dispatch.organisations.getOne(params.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	const crudHandlersOrga: CrudOptions = {
		handleEdit: (id: number) => {
			setOpenCreateUserDrawer(!openCreateUserDrawer);
			setType('update');
			const user = organisation.users.find((user) => user.id === id);
			setSelectedUser(user);
		},
		handleDelete: (id: number) => {
			setOpenDeleteDrawer(!openDeleteDrawer);
			const user = organisation.users.find((user) => user.id === id);
			setSelectedUser(user);
		},
	};

	const crudHandlers: CrudOptions = {
		handleEdit: (id: number) => {
			setOpenCreateUpdateFluxDrawer(true);
			const fluxFinded = flux.find((f) => f.id === id);
			setSelectedFlux(fluxFinded);
		},
		handleDelete: (id: number) => {
			setOpenDeleteDrawerFlux(true);
			const fluxFinded = flux.find((f) => f.id === id);
			setSelectedFlux(fluxFinded);
		},
		handleSee: (id: number) => {
			navigate(`../${menu.flux.path}/${id}`);
		},
	};

	const handleDelete = () => {
		dispatch.organisations
			.deleteFromOrganisation({ id: params.id, email: selectedUser?.email })
			.then(() => {
				setOpenDeleteDrawer(false);
			});
	};

	const handleDeleteFlux = () => {
		dispatch.flux.delete(selectedFlux?.id).then(() => {
			setOpenDeleteDrawerFlux(false);
		});
	};

	return (
		<Wrapper>
			<Stack
				direction={{ sm: 'column', md: 'row' }}
				alignItems='center'
				gap={2}
				justifyContent={{ sm: 'center', md: 'space-between', lg: 'center' }}
				style={{ position: 'relative', marginBottom: '35px' }}>
				{isMobile ? (
					<Stack sx={{ width: { xs: '100%' } }} alignItems='flex-start'>
						<GoBack title={t('orga.Retour liste des organisations')} />
					</Stack>
				) : (
					<GoBack title={t('orga.Retour liste des organisations')} />
				)}
				<Typography variant='h1'>
					{t('orga.Organisation')} {organisation.name}
				</Typography>
			</Stack>

			<Box sx={{ position: 'relative' }}>
				{isMobile ? (
					<Box sx={{ height: '40px', position: 'absolute', right: 0, top: -15 }}>
						<IconButton
							aria-label='edit'
							onClick={() => {
								setOpenCreateUserDrawer(!openCreateUserDrawer);
								setType('create');
								setSelectedUser(undefined);
							}}
							sx={{
								backgroundColor: theme.palette.primary.main,
								border: `1px solid ${theme.palette.primary.main}`,
								borderRadius: '4px',
								'&.MuiIconButton-root:hover': {
									backgroundColor: '#F6B8A2',
									border: `1px solid #F6B8A2`,
								},
							}}>
							<Add sx={{ color: '#fff' }} />
						</IconButton>
					</Box>
				) : (
					<Button
						onClick={() => {
							setOpenCreateUserDrawer(!openCreateUserDrawer);
							setType('create');
							setSelectedUser(undefined);
						}}
						sx={{ height: '40px', position: 'absolute', right: 0, top: -15 }}
						variant='contained'
						startIcon={<Add />}>
						{t('users.Ajouter un nouvel utilisateur')}
					</Button>
				)}

				<DataTable
					title={t('orga.Liste des utilisateurs')}
					columns={headCellsUserOrga}
					data={organisation.users || []}
					defaultSortId=''
					crudOptions={crudHandlersOrga}
					translation='users'
				/>
			</Box>

			<DataTable
				title={t('orga.Gestion des flux')}
				columns={headCellsVideoOrga}
				data={organisation.videos || []}
				defaultSortId=''
				translation='flux'
				crudOptions={crudHandlers}
				filter
			/>
			<DataTable
				title={t('orga.Historique')}
				columns={headCellsHistoryOrga}
				data={organisation.history || []}
				defaultSortId=''
				translation='historique'
			/>

			<CreateUpdateUser
				open={openCreateUserDrawer}
				setOpen={() => setOpenCreateUserDrawer(!openCreateUserDrawer)}
				data={{ ...selectedUser, name: organisation.name, id: params.id }}
				type={type}
			/>

			{selectedUser && (
				<DeleteDrawer
					open={openDeleteDrawer}
					setOpen={() => {
						setSelectedUser(undefined);
						setOpenDeleteDrawer(false);
					}}
					type=''
					handleDelete={handleDelete}
					data={{
						name: `${selectedUser.first_name} ${selectedUser.last_name}`,
						id: selectedUser.id,
					}}
				/>
			)}

			{selectedFlux && (
				<DeleteDrawer
					open={openDeleteDrawerFlux}
					setOpen={() => {
						setSelectedFlux(undefined);
						setOpenDeleteDrawerFlux(false);
					}}
					handleDelete={handleDeleteFlux}
					data={{
						name: selectedFlux.name,
						id: selectedFlux.id,
					}}
				/>
			)}

			<CreateUpdateFlux
				open={openCreateUpdateFluxDrawer}
				setOpen={() => {
					setSelectedFlux(undefined);
					setOpenCreateUpdateFluxDrawer(false);
				}}
				data={selectedFlux}
			/>
		</Wrapper>
	);
};

export default OrganisationDetails;
