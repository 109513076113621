import * as React from 'react';
import {
	Button,
	Checkbox,
	FormControl,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import CustomDrawer from '../../../../components/common/CustomDrawer';
import { useTranslation } from 'react-i18next';
import useGenerateField from '../../../../hooks/useGenerateField';
import useFields from '../constants/fields';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IUser } from '../../../../types/user-types';
import theme from '../../../../styles/theme';
import { useSelectField } from '../../../../hooks/useSelectField';

interface ICreateUpdateUserProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	data?: any;
	withSearch?: boolean;
	isCreating?: boolean;
	type: string;
}

const CreateUpdateUser: React.FC<ICreateUpdateUserProps> = ({
	open,
	setOpen,
	data,
	withSearch = false,
	isCreating = false,
	type,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { createFields, updateFields, updateFullFields, createFullFields } = useFields();
	const generateFields = useGenerateField();
	const { selectValues, handleSelectChange, resetState } = useSelectField();

	const [createNewUser, setCreateNewUser] = React.useState(!withSearch);
	const [checked, setChecked] = React.useState<string>('');
	const [usersList, setUsersList] = React.useState<IUser[]>([]);
	const [search, setSearch] = React.useState('');
	const [error, setError] = React.useState(false);

	const handleToggle = (email: string) => () => {
		setChecked(email);
	};

	const findCheckedUser = usersList.find((user) => user.email === checked);

	React.useEffect(() => {
		if (data && search.length >= 1) {
			dispatch.users.search({ id: data.id ?? 1, query: search }).then((res: any) => {
				if (res.status === 200) {
					let updatedUsersList = [...res.data.message];
					if (
						findCheckedUser &&
						!updatedUsersList.some((user) => user.email === findCheckedUser.email)
					) {
						updatedUsersList = [findCheckedUser, ...updatedUsersList];
					}
					setUsersList(updatedUsersList);
				}
			});
		} else {
			setUsersList([]);
			setChecked('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, search]);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		let hasEmptyField = false;

		const formValues: Record<string, string | number[]> = {};
		formData.forEach((value, key) => {
			if (!value) {
				hasEmptyField = true;
				setError(true);
			} else {
				if (key === 'organisations') {
					formValues[key] = (value as string).split(',').map(Number);
				} else {
					formValues[key] = value as string;
				}
			}
		});

		if (!hasEmptyField) {
			if (isCreating) {
				if (data) {
					dispatch.organisations
						.createUserToOrganisation({
							id: data ? data?.id : formValues.organisations,
							formValues,
						})
						.then(() => setOpen(false));
				} else {
					dispatch.users.createUser(formValues).then(() => setOpen(false));
				}
			} else {
				dispatch.users
					.update({
						id: data?.id,
						formValues,
					})
					.then(() => setOpen(false));
			}
		}
	};

	const handleAddExistingUser = () => {
		dispatch.organisations.addUserToOrganisation({ email: checked, id: data?.id }).then(() => {
			setOpen(false);
		});
	};

	const displayFields = () => {
		switch (type) {
			case 'create':
				return createFields;
			case 'update':
				return updateFields;
			case 'createFull':
				return createFullFields;
			case 'updateFull':
				return updateFullFields;

			default:
				return [];
		}
	};

	const displayTitle = () => {
		switch (type) {
			case 'create':
				return 'Créer un nouvel utilisateur';
			case 'update':
				return 'Modifier un utilisateur';
			case 'createFull':
				return 'Créer un nouvel utilisateur';
			case 'updateFull':
				return 'Modifier un utilisateur';
			default:
				return '';
		}
	};

	const handleClose = () => {
		setOpen(!open);
		setCreateNewUser(!withSearch);
		resetState();
	};

	return (
		<CustomDrawer
			open={open}
			setOpen={handleClose}
			title={
				!createNewUser
					? t('users.Ajouter un nouvel utilisateur')
					: t(`users.${displayTitle()}`)
			}
			text={
				(type === 'create' || type === 'update') && createNewUser
					? t("users.Nom de l'organisation")
					: undefined
			}
			goBack={createNewUser && withSearch ? () => setCreateNewUser(!withSearch) : undefined}>
			{createNewUser ? (
				<>
					{(type === 'create' || type === 'update') && (
						<Button
							disableRipple
							variant='custom'
							sx={{
								marginBottom: '48px',
								'&:hover': {
									cursor: 'default',
									backgroundColor: '#FFF',
								},
							}}>
							{t('orga.Organisation')} {data?.name}
						</Button>
					)}

					<form onSubmit={handleSubmit}>
						{displayFields().map((field: any) =>
							generateFields(field, data?.[field?.name], error, selectValues, handleSelectChange),
						)}
						{error && (
							<p
								style={{
									color: theme.palette.error.main,
									textAlign: 'right',
									marginTop: 10,
									fontSize: 14,
								}}>
								{t('common.Tous les champs sont obligatoires.')}
							</p>
						)}

						<Button
							type='submit'
							variant='contained'
							sx={{ margin: '25px 0 15px 0', width: '100%' }}>
							{t('common.Enregistrer')}
						</Button>
					</form>
				</>
			) : (
				<>
					<FormControl sx={{ width: '100%', marginBottom: '10px' }} variant='standard'>
						<InputLabel htmlFor='search'>{t('common.Rechercher')}</InputLabel>
						<Input
							value={search}
							onChange={(e: any) => setSearch(e.target.value)}
							id='search'
							type='text'
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										type='button'
										sx={{ p: '10px' }}
										aria-label='search'>
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>

					<List
						sx={{
							width: '100%',
							maxWidth: 360,
							bgcolor: 'background.paper',
							marginBottom: '10px',
						}}>
						{usersList.map(({ email, last_name, first_name }, index) => {
							const labelId = `checkbox-list-label-${index}`;

							return (
								<ListItem key={email} disablePadding>
									<ListItemButton
										role={undefined}
										onClick={handleToggle(email)}
										dense>
										<ListItemIcon>
											<Checkbox
												edge='start'
												checked={checked.indexOf(email) !== -1}
												tabIndex={-1}
												disableRipple
												inputProps={{ 'aria-labelledby': labelId }}
											/>
										</ListItemIcon>
										<ListItemText
											id={labelId}
											primary={
												<>
													<span>{`${first_name} ${last_name}`}</span>
													<br />
													<span style={{ color: '#555E68' }}>
														( {email} )
													</span>
												</>
											}
										/>
									</ListItemButton>
								</ListItem>
							);
						})}
					</List>

					<Button
						onClick={() => setCreateNewUser(true)}
						variant='custom'
						sx={{ marginBottom: '40px' }}
						startIcon={<AddIcon />}>
						{t('users.Créer un nouvel utilisateur')}
					</Button>
					<Button
						onClick={handleAddExistingUser}
						variant='contained'
						sx={{ margin: '15px 0', width: '100%' }}>
						{t('common.Enregistrer')}
					</Button>
				</>
			)}
		</CustomDrawer>
	);
};

export default CreateUpdateUser;
