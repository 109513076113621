import React from 'react';
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { useTranslation } from 'react-i18next';
import { Fullscreen } from '@mui/icons-material';

interface LocalisationProps {
	coordinates: { latitude: number; longitude: number };
}

const Localisation: React.FC<LocalisationProps> = ({ coordinates }) => {
	const { t } = useTranslation();
	const localisationFrameRef = React.useRef<HTMLImageElement>(null);

	const { latitude, longitude } = coordinates;

	let DefaultIcon = L.icon({
		iconUrl: icon,
		shadowUrl: iconShadow,
	});

	L.Marker.prototype.options.icon = DefaultIcon;

	const toggleFullScreen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		const videoFrame = localisationFrameRef.current;
		if (videoFrame) {
			if (videoFrame.requestFullscreen) {
				videoFrame.requestFullscreen();
			}
		}
	};

	return (
		<Card sx={{ width: '100%', height: '370px' }}>
			<CardContent>
				<Typography sx={{ fontSize: 15 }} variant='h6' gutterBottom>
					{t('flux.Localisation')}
				</Typography>
				<Box style={{ height: '300px', position: 'relative' }} ref={localisationFrameRef}>
					<MapContainer
						center={[latitude, longitude]}
						zoom={13}
						style={{ height: '100%', width: '100%' }}>
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
						/>
						<Marker position={[latitude, longitude]}>
							<Popup>
								<Typography variant='caption' style={{ marginRight: 10 }}>
									{t('flux.Latitude')} : {latitude}
								</Typography>
								<Typography variant='caption'>
									{t('flux.Longitude')} : {longitude}
								</Typography>
							</Popup>
						</Marker>
					</MapContainer>
					<IconButton
						onClick={toggleFullScreen}
						sx={{
							position: 'absolute',
							bottom: 8,
							right: 8,
							backgroundColor: '#fff',
							zIndex: 400
						}}>
						<Fullscreen />
					</IconButton>
				</Box>
			</CardContent>
		</Card>
	);
};

export default Localisation;
