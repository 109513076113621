import './styles/App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ContentRoutes from './components/layout/Content/ContentRoutes';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { authPages } from './routes/menu';
import Aside from './components/layout/Aside/Aside';
import CustomAlert from './components/common/CustomAlert';
import './i18n';
import { CircularProgress, Stack } from '@mui/material';
import ProtectedRoute from './components/layout/Content/ProtectedRoutes';
import Header from './components/layout/Header/Header';

function App() {
	const withoutAsidePages = [authPages.login, authPages.page404, authPages.forgetPaswword];

	return (
		<Suspense
			fallback={
				<Stack sx={{ height: '100vh' }} justifyContent='center' alignItems='center'>
					<CircularProgress />
				</Stack>
			}>
			<CustomAlert />

			<Routes>
				{withoutAsidePages.map((page) => (
					<Route key={page.path} {...page} />
				))}
				<Route
					path='*'
					element={
						<ProtectedRoute>
							<Aside />
							<Header />
						</ProtectedRoute>
					}
				/>
			</Routes>
			<ContentRoutes />
		</Suspense>
	);
}

export default App;
