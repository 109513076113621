import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import React from "react";
import { useTranslation } from "react-i18next";

const useFieldstypecam = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { organisations } = useSelector((state: RootState) => state.organisations);
	const { token } = useSelector((state: RootState) => state.auth);

	const type_cam = [{ 'name': 'RGB', 'label': 'Visual', 'id': 1 }, { 'name': 'Thermal', 'label': 'Thermal', 'id': 2 }, { 'name': 'Splitted', 'label': 'Visual + Thermal', 'id': 3 }];

	React.useEffect(() => {
		if (token) dispatch.organisations.getAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	const fields = [

		{
			type: 'select2',
			name: 'camera_type',
			label: t('flux.camera_type'),
			multiple: false,
			placeholder: t('flux.camera_type'),
			options: type_cam.map((camera) => {
				return { label: camera.label, value: camera.name };
			}),

		}
	];

	return { fields };
};

export default useFieldstypecam;
