import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const useFields = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { organisations } = useSelector((state: RootState) => state.organisations);
	const { token } = useSelector((state: RootState) => state.auth);

	React.useEffect(() => {
		if (token) dispatch.organisations.getAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	const createFullFields = [
		{
			type: 'text',
			name: 'last_name',
			label: t('users.Nom'),
			placeholder: 'Ex: Dupont',
		},
		{
			type: 'text',
			name: 'first_name',
			label: t('users.Prénom'),
			placeholder: 'Ex: Antoine',
		},
		{
			type: 'text',
			name: 'email',
			label: t('users.Email'),
			placeholder: 'Ex: antoine.dupont@email.com',
			style: { marginBottom: 35 },
		},
		{
			type: 'password',
			name: 'password',
			label: t('users.Créer mon mot de passe'),
			style: { marginBottom: 35 },
		},
		{
			type: 'select',
			name: 'organisations',
			label: t('users.Organisation(s)'),
			multiple: true,
			placeholder: t('users.Choissisez une organisation'),
			options: organisations.map((organisation) => {
				return { label: organisation.name, value: organisation.id };
			}),
			style: { marginBottom: 0 },
		},
	];

	const createFields = [
		{
			type: 'text',
			name: 'last_name',
			label: t('users.Nom'),
			placeholder: 'Ex: Dupont',
		},
		{
			type: 'text',
			name: 'first_name',
			label: t('users.Prénom'),
			placeholder: 'Ex: Antoine',
		},
		{
			type: 'text',
			name: 'email',
			label: t('users.Email'),
			placeholder: 'Ex: antoine.dupont@email.com',
		},
		{
			type: 'password',
			name: 'password',
			label: t('users.Créer mon mot de passe'),
			style: { marginBottom: 0 },
		},
	];

	const updateFields = [
		{
			type: 'text',
			name: 'last_name',
			label: t('users.Nom'),
			placeholder: 'Ex: Dupont',
		},
		{
			type: 'text',
			name: 'first_name',
			label: t('users.Prénom'),
			placeholder: 'Ex: Antoine',
		},
		{
			type: 'text',
			name: 'email',
			label: t('users.Email'),
			placeholder: 'Ex: antoine.dupont@email.com',
			style: { marginBottom: 0 },
		},
	];

	const updateFullFields = [
		{
			type: 'text',
			name: 'last_name',
			label: t('users.Nom'),
			placeholder: 'Ex: Dupont',
		},
		{
			type: 'text',
			name: 'first_name',
			label: t('users.Prénom'),
			placeholder: 'Ex: Antoine',
		},
		{
			type: 'text',
			name: 'email',
			label: t('users.Email'),
			placeholder: 'Ex: antoine.dupont@email.com',
		},
		{
			type: 'select',
			name: 'organisations',
			label: t('users.Organisation(s)'),
			multiple: true,
			placeholder: t('users.Choissisez une organisation'),
			options: organisations.map((organisation) => {
				return { label: organisation.name, value: organisation.id };
			}),
			style: { marginBottom: 0 },
		},
	];

	return { createFields, updateFields, createFullFields, updateFullFields };
};

export default useFields;
