import { createModel } from '@rematch/core';
import { RootModel } from '..';
import { API_ENDPOINT, httpSecured } from '../../services/http';
import { INotification, INotifications } from '../../types/notifications-types';

export const notifications = createModel<RootModel>()({
	state: {
		notifications: [] as INotifications[],
		notification: {} as INotification,
		count: 0,
	},
	reducers: {
		set: (state, payload, subject) => {
			return { ...state, [subject]: payload };
		},
		count: (state, notifications) => {
			const unreadNotificationsCount = notifications.filter(
				(notification: INotification) => !notification.read,
			).length;
			return { ...state, count: unreadNotificationsCount };
		},
	},
	effects: (dispatch) => ({
		async getAll() {
			try {
				const { data } = await httpSecured.get(API_ENDPOINT.NOTIFICATION.GET_NOTIFICATIONS);
				if (data) {
					this.set(data.history, 'notifications');
					this.count(data.history);
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async getOne(id: number) {
			try {
				const { data } = await httpSecured.get(
					`${API_ENDPOINT.NOTIFICATION.GET_NOTIFICATION}${id}`,
				);
				if (data) {
					this.set(data, 'notification');
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
			}
		},

		async setToRead(id) {
			try {
				const { data } = await httpSecured.put(
					`${API_ENDPOINT.NOTIFICATION.SET_TO_READ}${id}/`,
					{ notif_id: id },
				);
				if (data) {
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
	}),
});
