import React from 'react';

export type SelectValues = {
	[fieldName: string]: string | number | (string | number)[];
};

// Créez un hook personnalisé pour gérer l'état global des champs Select
export const useSelectField: any = () => {
	const [selectValues, setSelectValues] = React.useState<SelectValues>({});

	// Fonction de mise à jour de l'état global pour les champs Select
	const handleSelectChange = (
		fieldName: string,
		value: string | number | (string | number)[],
	) => {
		setSelectValues((prevState) => {
			console.log('prevState', prevState);
			return {
				...prevState,
				[fieldName]: value,
			};
		});
	};

	const resetState = () => {
		setSelectValues({});
	};

	return {
		selectValues,
		handleSelectChange,
		resetState,
	};
};
