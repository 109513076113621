// eslint-disable-next-line import/named
import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import { models, RootModel } from '.';
import createPersistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage';
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading';

const persistPlugin: any = createPersistPlugin({
	key: 'root',
	storage,
	version: 2,
	whitelist: ['auth'],
});

type FullModel = ExtraModelsFromLoading<RootModel>;

export const store = init<RootModel, FullModel>({
	models,
	plugins: [persistPlugin, loadingPlugin()],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
