import { FC } from 'react';
import { Button, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';
import theme from '../../styles/theme';
import { SxProps } from '@mui/system';

interface IGoBackProps {
	title: string;
	style?: SxProps;
	path?: Function;
}
const GoBack: FC<IGoBackProps> = ({
	title,
	style = { position: { lg: 'absolute' } },
	path,
}): JSX.Element => {
	const navigate = useNavigate();
	return title ? (
		<Button
			onClick={() => (path ? path() : navigate(-1))}
			sx={{
				left: 0,
				textTransform: 'none',
				color: theme.palette.text.primary,
				height: 40,
				backgroundColor: '#fff',
				...style,
			}}
			startIcon={<KeyboardArrowLeftIcon />}>
			{title}
		</Button>
	) : (
		<IconButton
			onClick={() => (path ? path() : navigate(-1))}
			sx={{
				left: 0,
				textTransform: 'none',
				color: theme.palette.text.primary,
				height: 40,
				backgroundColor: '#fff',
				...style,
			}}>
			<KeyboardArrowLeftIcon />
		</IconButton>
	);
};

export default GoBack;
