import { createModel } from '@rematch/core';
import { RootModel } from '..';
import { API_ENDPOINT, httpSecured } from '../../services/http';
import { IKpisState } from '../../types/dashboard-types';

export const dashboard = createModel<RootModel>()({
	state: {
		kpis: [],
	} as IKpisState,
	reducers: {
		setKpis: (state, payload) => {
			return { ...state, kpis: payload };
		},
	},
	effects: (dispatch) => ({
		async getKpis(date) {
			try {
				const { data } = await httpSecured.get(`${API_ENDPOINT.DASHBOARD.GET_KPI}${date ? `&date=${date}` : ''}`);
				if (data) {
					dispatch.dashboard.setKpis(data);
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async exportCSV() {
			try {
				const { data } = await httpSecured.get(API_ENDPOINT.DASHBOARD.EXPORT_CSV);
				if (data) {
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
	}),
});
