import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../../store/store';

type Props = {
	children: React.ReactNode;
};

const ProtectedRoute = ({ children }: Props) => {
	const { token } = useSelector((state: RootState) => state.auth);

	if (!token) {
		return <Navigate to='/auth-login' replace />;
	}

	return <>{children}</>};

export default ProtectedRoute;
