import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import { FilterListOutlined } from '@mui/icons-material';
import {
	Checkbox,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		padding: '8px',
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity,
				),
			},
		},
	},
}));

interface IFilterProps {
    list: any[]
	setFilteredData: (data: any) => void;
}
const Filter: React.FC<IFilterProps> = ({  setFilteredData, list }) => {
	const { t } = useTranslation();
	
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [checked, setChecked] = React.useState<string[]>([]);

	const handleToggle = (value: string) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
            const filteredResult = list.filter((item) => newChecked.includes(item.name));
            setFilteredData(filteredResult);
		} else {
			newChecked.splice(currentIndex, 1);
            const filteredResult = list.filter((item) => newChecked.includes(item.name));
            setFilteredData(filteredResult);

		}


		setChecked(newChecked);
	};

	return (
		<div>
			<Button
				aria-controls={open ? 'demo-customized-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				disableElevation
				onClick={handleClick}
				startIcon={<FilterListOutlined />}>
				{t('common.Filtrer')}
			</Button>
			<StyledMenu
				id='demo-customized-menu'
				MenuListProps={{
					'aria-labelledby': 'demo-customized-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}>
				<Stack direction='row' alignItems='center' gap={1} sx={{ marginBottom: '10px' }}>
					<FilterListOutlined color='primary' />
					<Typography sx={{ fontWeight: 600, fontSize: '13px' }}>{t('common.Filtrer')}</Typography>
				</Stack>
				<Typography sx={{ fontWeight: 700, fontSize: '11px' }}>
					{t('flux.Type de détection')}
				</Typography>
				<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
					{list.map(({ name }) => {
						const labelId = `checkbox-list-label-${name}`;

						return (
							<ListItem key={name} disablePadding>
								<ListItemButton role={undefined} onClick={handleToggle(name)} dense>
									<ListItemIcon>
										<Checkbox
											edge='start'
											checked={checked.indexOf(name) !== -1}
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText id={labelId} primary={name} />
								</ListItemButton>
							</ListItem>
						);
					})}
				</List>
			</StyledMenu>
		</div>
	);
};

export default Filter;
