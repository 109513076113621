import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { CrudOptions, IData, IHeadCellGlobal, Order } from '../../types/datatable-types';
import { convertDateToDifferentFormat, getComparator, stableSort } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { IHistory, IOrgaUser, IOrganisation } from '../../types/organisations-types';
import { IVideo } from '../../types/flux-types';
import { IconButton, Stack, TablePagination, Typography, useMediaQuery } from '@mui/material';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import CrudCell from './CRUDCell';
import { IUser } from '../../types/user-types';
import { InsertLink } from '@mui/icons-material';
import { displayDetectionType, displayVideoStatus } from '../../utils/detectionUtils';
import { INotifications } from '../../types/notifications-types';
import Filter from './Filter';
import theme from '../../styles/theme';

type IDataProps =
	| IData[]
	| IOrganisation[]
	| IOrgaUser[]
	| IVideo[]
	| IHistory[]
	| IUser[]
	| INotifications[];

interface IDatatableProps {
	withCheckbox?: boolean;
	columns: IHeadCellGlobal[];
	data: IDataProps;
	translation?: string;
	defaultSortId: string;
	crudOptions?: CrudOptions;
	title?: string;
	currentPage?: number;
	setCurrentPage?: (e: number) => void;
	totalPageCount?: number;
	rowPerPage?: number;
	setRowPerPage?: (e: number) => void;
	filter?: boolean;
	noDataText?: string;
}

const DataTable: React.FC<IDatatableProps> = ({
	withCheckbox = false,
	columns,
	data,
	translation,
	defaultSortId,
	crudOptions,
	title,
	currentPage,
	setCurrentPage,
	totalPageCount,
	rowPerPage,
	setRowPerPage,
	filter,
	noDataText = 'Aucune donnée disponible',
}): JSX.Element => {
	const { t } = useTranslation();

	const [filteredData, setFilteredData] = React.useState<IDataProps>([]);

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	React.useEffect(() => {
		if (data?.length > 0) setFilteredData(data);
	}, [data]);

	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<keyof IData>(defaultSortId);
	const [selected, setSelected] = React.useState<readonly number[]>([]);

	const [page, setPage] = React.useState(currentPage ?? 0);
	const [rowsPerPage, setRowsPerPage] = React.useState(rowPerPage ?? 5);

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IData) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = data?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	React.useEffect(() => {
		// Scroll to the top of the page when component mounts
		window.scrollTo(0, 0);
	}, []);

	const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		if (setCurrentPage) setCurrentPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		if (setCurrentPage) setCurrentPage(0);
		if (setRowPerPage) setRowPerPage(parseInt(event.target.value, 10));
	};

	const isSelected = (id: number) => selected.indexOf(id) !== -1;

	const sortedData = React.useMemo(() => {
		if (setCurrentPage) {
			return stableSort(filteredData as IData[], getComparator(order, orderBy));
		} else {
			return stableSort(filteredData as IData[], getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage,
			);
		}
	}, [order, orderBy, page, rowsPerPage, filteredData, setCurrentPage]);

	const createSortHandler = (property: keyof IData) => (event: React.MouseEvent<unknown>) => {
		handleRequestSort(event, property);
	};

	const getCellContent = (headCell: IHeadCellGlobal, row: any) => {
		if (headCell.id === "camera_type") {
			if (row[headCell.id] == "Splitted") { return "Visual + Thermique" }
		}
		if (headCell.id === "camera_type") {
			if (row[headCell.id] == "RGB") { return "Visual" }
		}
		if (Array.isArray(headCell.id)) {
			const concatenatedValues = (headCell.id as string[]).map((id) => row[id]).join(' ');
			return concatenatedValues.length === 0 ? '-' : concatenatedValues;
		}
		if (headCell.dateFormat) {
			if (row[headCell.id as string] === 'None') return '-';
			return convertDateToDifferentFormat(row[headCell.id as string]);
		}
		if (headCell.populate) {
			const populateValues = row[headCell.id]
				?.map((el: any) => el[headCell.populate as string])
				?.join(', ');
			return populateValues?.length === 0 ? '-' : populateValues;
		}
		if (headCell.id === 'source' && row[headCell.id as string]?.toUpperCase() === 'URL') {
			return (
				<IconButton
					disabled={!row?.url}
					aria-label='url-btn'
					onClick={() => {
						if (row?.url) window.open(row?.url, '_blank');
					}}>
					<InsertLink titleAccess={row?.url} />
				</IconButton>
			);
		}
		if ((headCell.id as string) === 'detection_type') {
			return displayDetectionType(row[headCell.id]);
		}
		if ((headCell.id as string) === 'video_status') {
			return displayVideoStatus(row[headCell.id]);
		}

		return row[headCell.id as string] || '-';
	};

	return (
		<Box sx={{ width: '100%', marginBottom: '80px' }}>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={{ marginBottom: '18px' }}>
				{title && <Typography variant='h2'>{title}</Typography>}
				{filter && <Filter list={data} setFilteredData={setFilteredData} />}
			</Stack>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'medium'}>
						<TableHead>
							<TableRow>
								{withCheckbox && (
									<TableCell padding='checkbox'>
										<Checkbox
											color='primary'
											indeterminate={
												selected.length > 0 &&
												selected.length < filteredData.length
											}
											checked={
												filteredData.length > 0 &&
												selected.length === filteredData.length
											}
											onChange={handleSelectAllClick}
											inputProps={{
												'aria-label': 'select all',
											}}
										/>
									</TableCell>
								)}
								{columns.map((headCell, index) => {
									const customId = Array.isArray(headCell.id)
										? headCell.id[0]
										: headCell.id;
									return (
										<TableCell
											className={index === 0 && isMobile ? 'sticky' : ''}
											key={`${title}-row-${customId}`}
											align={'left'}
											padding={
												headCell.disablePadding && withCheckbox
													? 'none'
													: 'normal'
											}
											sortDirection={orderBy === headCell.id ? order : false}>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={createSortHandler(customId)}>
												{t(`${translation}.${headCell.label}`)}
												{orderBy === headCell.id ? (
													<Box component='span' sx={visuallyHidden}>
														{order === 'desc'
															? 'sorted descending'
															: 'sorted ascending'}
													</Box>
												) : null}
											</TableSortLabel>
										</TableCell>
									);
								})}
								{crudOptions && filteredData.length > 0 && (
									<TableCell align={'left'}>{t('common.Actions')}</TableCell>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{sortedData.length > 0 ? (
								sortedData.map((row, index) => {
									const isItemSelected = isSelected(row.id);
									const labelId = `enhanced-table-checkbox-${row.id}-${index}`;
									return (
										<TableRow
											hover
											onClick={(event) => {
												if (withCheckbox) handleClick(event, row.id);
											}}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={labelId}
											selected={withCheckbox ? isItemSelected : false}
											sx={{
												'&.MuiTableRow-root:hover': {
													backgroundColor: '#FEF4F1',
												},
												backgroundColor:
													row.read === undefined
														? 'transparent'
														: row.read
															? 'transparent'
															: '#FEF4F1',
												cursor: crudOptions?.handleSee
													? 'pointer'
													: 'default',
											}}>
											{withCheckbox && (
												<TableCell padding='checkbox'>
													<Checkbox
														color='primary'
														checked={isItemSelected}
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												</TableCell>
											)}
											{columns.map((headCell, index) => {
												console.log('coulun', headCell);
												const customId = Array.isArray(headCell.id)
													? `${headCell.id[0]}-${index}`
													: `${headCell.id}-${index}`;
												return (
													<TableCell
														className={
															index === 0 && isMobile ? 'sticky' : ''
														}
														onClick={() => {
															if (crudOptions?.handleSee)
																crudOptions?.handleSee(row.id);
														}}
														key={customId}
														component='th'
														id={labelId}
														scope='row'
														align={'left'}
														padding={
															headCell.disablePadding && withCheckbox
																? 'none'
																: 'normal'
														}>
														{getCellContent(headCell, row)}
													</TableCell>
												);
											})}
											{crudOptions && (
												<TableCell
													component='th'
													id={labelId}
													scope='row'
													align={'right'}
													padding={'normal'}>
													<CrudCell
														rowId={row.id}
														onAdd={crudOptions.handleAdd}
														onSee={crudOptions.handleSee}
														onEdit={crudOptions.handleEdit}
														onDelete={crudOptions.handleDelete}
														onArchive={crudOptions.handleArchive}
													/>
												</TableCell>
											)}
										</TableRow>
									);
								})
							) : (
								<TableRow>
									<TableCell
										colSpan={
											columns.length +
											(withCheckbox ? 1 : 0) +
											(crudOptions ? 1 : 0)
										}>
										<Box
											textAlign='center'
											p={3}
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												fontWeight: 400,
											}}>
											<FolderOffOutlinedIcon
												sx={{
													height: 40,
													width: 40,
													marginBottom: '10px',
												}}
											/>
											{t(`common.${noDataText}`)}
										</Box>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{filteredData?.length > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component='div'
					count={totalPageCount ? totalPageCount : filteredData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={t('common.rowPerPage')}
				/>
			)}
		</Box>
	);
};

export default DataTable;
