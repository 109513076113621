import * as React from 'react';
import DataTable from '../../../components/datatable/Datatable';
import Wrapper from '../../../components/layout/Wrapper/Wrapper';
import { Box, Button, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { headCells } from './constants/columns';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { menu } from '../../../routes/menu';
import { useNavigate } from 'react-router-dom';
import { IOrganisation } from '../../../types/organisations-types';
import AddIcon from '@mui/icons-material/Add';
import CreateUpdateOrganisation from './components/CreateUpdate';
import CreateUpdateUser from '../users/components/CreateUpdate';
import DeleteDrawer from '../../../components/common/DeleteDrawer';
import { CrudOptions } from '../../../types/datatable-types';
import theme from '../../../styles/theme';

const OrganisationsList = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { organisations } = useSelector((state: RootState) => state.organisations);

	const [openDeleteDrawer, setOpenDeleteDrawer] = React.useState(false);
	const [openCreateUpdateOrgaDrawer, setOpenCreateUpdateOrgaDrawer] = React.useState(false);
	const [openCreateUserDrawer, setOpenCreateUserDrawer] = React.useState(false);

	const [selectedOrganisation, setSelectedOrganisation] = React.useState<IOrganisation>();

	React.useEffect(() => {
		dispatch.organisations.getAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const crudHandlers: CrudOptions = {
		handleAdd: (id: number) => {
			const organisation = organisations.find((orga) => orga.id === id);
			setSelectedOrganisation(organisation);
			setOpenCreateUserDrawer(!openCreateUserDrawer);
		},
		handleSee: (id: number) => {
			navigate(`../${menu.parameters.subMenu.organisations.path}/${id ?? 1}`);
		},
		handleEdit: (id: number) => {
			setOpenCreateUpdateOrgaDrawer(!openCreateUpdateOrgaDrawer);
			const organisation = organisations.find((orga) => orga.id === id);
			setSelectedOrganisation(organisation);
		},
		handleDelete: (id: number) => {
			setOpenDeleteDrawer(true);
			const organisation = organisations.find((orga) => orga.id === id);
			setSelectedOrganisation(organisation);
		},
	};

	const handleDelete = () => {
		dispatch.organisations.delete(selectedOrganisation?.id).then(() => {
			setOpenDeleteDrawer(false);
		});
	};

	return (
		<Wrapper>
			<Stack direction='row' justifyContent='space-between' sx={{ marginBottom: '35px' }}>
				<Typography variant='h1'>{t('orga.Liste des organisations')}</Typography>
				{isMobile ? (
					<Box>
						<IconButton
							aria-label='edit'
							onClick={() =>
								setOpenCreateUpdateOrgaDrawer(!openCreateUpdateOrgaDrawer)
							}
							sx={{
								backgroundColor: theme.palette.primary.main,
								border: `1px solid ${theme.palette.primary.main}`,
								borderRadius: '4px',
								'&.MuiIconButton-root:hover': {
									backgroundColor: '#F6B8A2',
									border: `1px solid #F6B8A2`,
								},
							}}>
							<AddIcon sx={{ color: '#fff' }} />
						</IconButton>
					</Box>
				) : (
					<Button
						onClick={() => setOpenCreateUpdateOrgaDrawer(!openCreateUpdateOrgaDrawer)}
						sx={{ height: '40px' }}
						variant='contained'
						startIcon={<AddIcon />}>
						{t('orga.Créer une nouvelle organisation')}
					</Button>
				)}
			</Stack>
			<DataTable
				columns={headCells}
				data={organisations}
				defaultSortId='name'
				crudOptions={crudHandlers}
				translation='orga'
			/>
			
			{selectedOrganisation && (
				<DeleteDrawer
					open={openDeleteDrawer}
					setOpen={() => {
						setSelectedOrganisation(undefined);
						setOpenDeleteDrawer(false);
					}}
					handleDelete={handleDelete}
					type={t('orga.Organisation')}
					data={{
						name: selectedOrganisation.name,
						id: selectedOrganisation.id,
					}}
				/>
			)}

			<CreateUpdateOrganisation
				open={openCreateUpdateOrgaDrawer}
				setOpen={() => {
					setSelectedOrganisation(undefined);
					setOpenCreateUpdateOrgaDrawer(false);
				}}
				data={selectedOrganisation}
			/>

			<CreateUpdateUser
				open={openCreateUserDrawer}
				setOpen={() => {
					setSelectedOrganisation(undefined);
					setOpenCreateUserDrawer(false);
				}}
				data={selectedOrganisation}
				withSearch
				isCreating
				type='create'
			/>
		</Wrapper>
	);
};

export default OrganisationsList;
