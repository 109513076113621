import { Card, Stack } from '@mui/material';
import * as React from 'react';
import { IDetection } from '../../../types/dashboard-types';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

interface IFluxCardProps {
	data: IDetection[];
}

const FluxCard: React.FC<IFluxCardProps> = ({ data }): JSX.Element => {
	const size = {
		width: 400,
		height: 200,
	};

	const colors = ['#4D69FA', '#46BCAA', '#FFA2C0', '#6C5DD3'];

	return (
		<Card sx={{ width: '360px', padding: '15px' }}>
			<Stack direction='row' alignItems='center' style={{ position: 'relative' }}>
				<PieChart
					colors={colors}
					series={[
						{
							arcLabel: (item) => `${item.value}%`,
							arcLabelMinAngle: 45,
							data,
						},
					]}
					sx={{
						[`& .${pieArcLabelClasses.root}`]: {
							fill: 'white',
							fontWeight: '400',
						},
					}}
					{...size}
				/>
			</Stack>
		</Card>
	);
};

export default FluxCard;
