import * as React from 'react';
import {
	Badge,
	Box,
	Button,
	Card,
	CardContent,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { INotifications } from '../../../types/notifications-types';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, formatDate } from '../../../utils/helpers';
import { Fullscreen, VisibilityOutlined } from '@mui/icons-material';
import { notificationsPages } from '../../../routes/menu';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

interface INotificationCard {
	data: INotifications;
}

const NotificationCard: React.FC<INotificationCard> = ({ data }): JSX.Element => {
	const { detection_date, detection_type, name, organisations, read, image } = data;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const videoFrameRef = React.useRef<HTMLImageElement>(null);

	const handleOpenNotification = () => {
		if (!read) {
			dispatch.notifications.setToRead(data.id);
		}
		navigate(`../${notificationsPages.read.path}/${data.id}`);
	};

	const toggleFullScreen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		const videoFrame = videoFrameRef.current;
		if (videoFrame) {
			if (videoFrame.requestFullscreen) {
				videoFrame.requestFullscreen();
			}
		}
	};

	return (
		<Card
			onClick={handleOpenNotification}
			sx={{
				cursor: 'pointer',

				marginBottom: '20px',
				backgroundColor: read ? '#F6F6F6' : '#FFFFFF',
				boxShadow: read
					? 'initial'
					: `0px 2px 1px -1px rgba(241, 138, 101, 0.2), 
                0px 1px 1px 0px rgba(241, 138, 101, 0.14), 
                0px 1px 3px 0px rgba(241, 138, 101, 0.12)`,
				border: read ? 'initial' : `.8px solid rgba(241, 138, 101, 0.8)`,
				'&:hover': {
					boxShadow:
						'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
				},
			}}>
			<CardContent sx={{ display: 'flex', padding: '5px 20px', paddingBottom: '5px !important' }}>
				<Card
					sx={{
						position: 'relative',
						marginRight: '38px',
						padding: '10px',
						minWidth: '280px',
						boxShadow: 'none',
						zIndex: 2,
						display: { xs: 'none', sm: 'flex' },
					}}>
					<img
						src={`data:image/jpeg;base64,${image}`}
						alt='thermical-camera'
						ref={videoFrameRef}
						style={{
							width: '100%',
							objectFit: 'fill',
							borderRadius: 5,
						}}
					/>
					<IconButton
						onClick={(e) => toggleFullScreen(e)}
						sx={{
							width: '25px',
							height: '25px',
							position: 'absolute',
							bottom: 12,
							right: 12,
							backgroundColor: '#fff',
						}}>
						<Fullscreen />
					</IconButton>
				</Card>

				<Stack
					sx={{ width: '100%', padding: '15px 0' }}
					direction='row'
					justifyContent='space-between'
					gap={2}>
					<Stack>
						<Box>
							<Typography
								style={{
									fontSize: '16px',
									fontWeight: 700,
								}}>
								{t('flux.Type de détection')}
							</Typography>
							<Typography sx={{ marginBottom: '20px' }}>
								<Badge
									color='error'
									variant='dot'
									style={{ margin: '0 20px 0 10px' }}
								/>
								{capitalizeFirstLetter(detection_type)}
							</Typography>
						</Box>

						<Stack direction={{ sm: 'column', md: 'row' }}>
							<Box sx={{ marginRight: '40px', marginBottom: { xs: '10px' } }}>
								<Typography style={{ fontSize: '16px', fontWeight: 700 }}>
									{t('orga.Organisation')}
								</Typography>
								<Typography>
									{organisations.map((orga) => orga.name)?.join(', ')}
								</Typography>
							</Box>
							<Box>
								<Typography style={{ fontSize: '16px', fontWeight: 700 }}>
									{t('flux.Nom du flux')}
								</Typography>
								<Typography>{name}</Typography>
							</Box>
						</Stack>
					</Stack>

					<Stack sx={{ marginBottom: '10px', justifyContent: 'space-between' }}>
						<Box>
							<Typography
								style={{
									fontSize: '16px',
									fontWeight: 700,
								}}>
								{t('flux.Date de détection')}
							</Typography>
							<Typography sx={{ marginBottom: '20px' }}>
								{formatDate(detection_date)}
							</Typography>
						</Box>

						<Button
							onClick={handleOpenNotification}
							sx={{ height: '40px', backgroundColor: read ? '#fff' : '' }}
							variant={read ? 'outlined' : 'contained'}
							startIcon={<VisibilityOutlined />}>
							{t('notifications.Voir la détection')}
						</Button>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default NotificationCard;
